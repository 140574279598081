.frontPage {
    width: 100vw;
    height: 100vh;
    z-index: -5;
    object-fit: contain;
}

.navBar {
    position: fixed;
    width: 100%;
    display: flex;
    height: 6%;
    justify-content: center;
    align-items: center;
    z-index: 5;
    li {
        display: inline-block;
        margin: 0 2%;
    }
    a {
        text-decoration: none;
        font-size: 1.7vh;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        color: white;
        font-weight: bold;
        z-index: -10 !important;
        text-transform: uppercase;
        transition: 0.5s;
    }
}

.scroll {
    background-color: #1c1818;
}
