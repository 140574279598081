.projects {
    margin-top: 20rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 84px;
    border-top-right-radius: 84px;
    z-index: -4;
    h1 {
        margin-top: 2.5%;
        font-size: 2.5rem;
        text-align: center;
    }

    .purpleBox {
        background-color: #4f576c;
        height: 2vh;
        width: 250px;
        text-align: center;
    }

    .project {
        margin-top: 5rem;
        display: flex;
        justify-content: space-evenly;
        height: 15%;
        width: 80%;
        z-index: 1;

        .project-pic {
            width: 30rem;
            height: auto;
            border: 1px solid black;
        }
        h3 {
            font-weight: bold;
            color: #252934;
            font-size: 2rem;
        }
        h4 {
            color: #657396;
            font-weight: 600;
            font-size: 1.5rem;
            margin: 0;
        }

        h5 {
            font-family: Montserrat;
            font-style: normal;
            font-size: 1rem;
            color: #ffffff;
            font-weight: 600;
            margin: 0;
        }
        ul li b {
            opacity: 0.85;
        }
        .languages {
            display: flex;
        }
        .description {
            width: 40%;
        }
    }
}

$laptop-breakpoint-tablet: 1000px;
@media (max-width: $laptop-breakpoint-tablet) {
    .projects {
        .project {
            flex-direction: column;
            align-items: center;
            text-align: center;
            .description {
                width: 60%;
            }
        }
    }
}

$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
    .project-pic {
        display: none;
        width: 0;
        height: 0;
    }
    .projects .project {
        width: 100%;
        h3 {
            text-align: center;
            font-size: 1.5rem;
        }
        h4 {
            text-align: center;
        }
        h5 {
            text-align: center;
        }
        .description {
            width: 80%;
        }
        .languages {
            display: flex;
            justify-content: center;

            h5 {
                font-size: 0.7rem;
            }
        }
    }
}
