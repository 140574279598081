.experience {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 84px;
    border-top-right-radius: 84px;
    h1 {
        margin-top: 2.5%;
        font-size: 2.5rem;
        text-align: center;
    }

    .purpleBox {
        background-color: #4f576c;
        height: 2vh;
        width: 250px;
        text-align: center;
    }
    .workExperience {
        margin-top: 5rem;
        display: flex;
        justify-content: space-between;
        height: 45%;
        width: 70%;
        h3 {
            font-weight: bold;
            text-align: left;
            font-size: 1.75rem;
        }
        h4 {
            font-style: italic;
            font-size: 1.5rem;
            font-weight: normal;
        }
        h5 {
            font-size: 1.25rem;
            font-weight: normal;
        }
        .workDetails {
            width: 40%;
            ul li {
                line-height: 25px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .experience {
        .workExperience {
            width: 100%;
            height: 45%;
            flex-direction: column;
            align-items: center;
            h3 {
                text-align: center;
            }
            h4 {
                text-align: center;
            }
            h5 {
                text-align: center;
            }
            ul {
                margin: 0;
                padding: 0;
            }
            .workDetails {
                margin-top: 5%;
                width: 70%;
            }
        }
    }
    li {
        line-height: 20px;
    }
}
