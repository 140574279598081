.app {
    background-image: url("https://user-images.githubusercontent.com/52011886/116297565-643c2500-a769-11eb-966f-76b4c5f6c84f.png");
    background-repeat: repeat;
    /* background-size: 1%; */
    /* background-position: center; */
    font-family: Montserrat;
    font-style: normal;
}

html {
    scroll-behavior: smooth;
}
