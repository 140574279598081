.firstPage {
    width: 100vw;
    height: 100vh;
    z-index: -5;
    object-fit: contain;
    display: flex;
    align-items: center;
}

.about {
    // width: 100%;
    height: 35rem;
    background: rgba(17, 20, 28, 0.75) no-repeat center fixed;
    background-size: cover;
    display: flex;
    // -webkit-text-stroke-width: 0.5px;
    // -webkit-text-stroke-color: black;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    margin: 0;

    .profile-pic {
        width: 364px;
        height: 432px;
    }

    .blueBox {
        background-color: #a4b8ff;
        height: 2vh;
        width: 200px;
    }

    .description {
        display: flex;
        flex-direction: column;
        font-family: Montserrat !important;
        font-style: normal;
        width: 40%;
    }
    h3 {
        font-size: 2.5rem;
        font-weight: bold;
        margin: 0;
    }
    p {
        margin-top: 5%;
        font-size: 1rem;
        line-height: 29px;
    }
    b {
        opacity: 0.33;
    }
}

@media (max-width: 890px) {
    .profile-pic {
        display: none;
    }
    .blueBox {
        background-color: #a4b8ff;
        text-align: center;
    }
    .about {
        height: 100vh;
        text-align: center;
        .description {
            align-items: center;
            width: 90%;
        }
    }
}
